
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6728_70810)">
<path d="M8.66321 6.54547V9.64368H13.0565C12.8636 10.64 12.2847 11.4837 11.4164 12.051L14.0658 14.0656C15.6094 12.6692 16.4999 10.6183 16.4999 8.18192C16.4999 7.61466 16.448 7.06915 16.3515 6.54556L8.66321 6.54547Z" fill="#4285F4"/>
<path d="M4.08828 9.52274L3.49075 9.97099L1.37567 11.5855C2.7189 14.1964 5.47196 16.0001 8.66305 16.0001C10.8671 16.0001 12.7149 15.2874 14.0656 14.0656L11.4163 12.051C10.689 12.531 9.76134 12.8219 8.66305 12.8219C6.5406 12.8219 4.7373 11.4183 4.09161 9.52735L4.08828 9.52274Z" fill="#34A853"/>
<path d="M1.3757 4.41457C0.819139 5.49089 0.500061 6.70546 0.500061 7.99999C0.500061 9.29452 0.819139 10.5091 1.3757 11.5854C1.3757 11.5926 4.0919 9.51995 4.0919 9.51995C3.92864 9.03995 3.83213 8.53089 3.83213 7.99991C3.83213 7.46893 3.92864 6.95987 4.0919 6.47986L1.3757 4.41457Z" fill="#FBBC05"/>
<path d="M8.66322 3.18547C9.86547 3.18547 10.9341 3.59273 11.7875 4.3782L14.1252 2.08731C12.7077 0.792781 10.8673 0 8.66322 0C5.47213 0 2.7189 1.79637 1.37567 4.41458L4.09179 6.48004C4.7374 4.5891 6.54077 3.18547 8.66322 3.18547Z" fill="#EA4335"/>
</g>
<defs>
<clipPath id="clip0_6728_70810">
<rect width="16" height="16" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'IconGoogle',
    inheritAttrs: true,
  }
  </script>
